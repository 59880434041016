import React from "react"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

class ContactIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} title={`Contact`}>
        <SEO
          title="Contact"
          keywords={[`links`, `jmfurlott`, `javascript`, `react`]}
        />
        <h2>Contact</h2>
        <p>
          Please contact me at{" "}
          <a
            target="_blank"
            className="is-active"
            href="mailto:jmfurlott@gmail.com"
          >
            jmfurlott@gmail.com
          </a>
          . Or, on Twitter{" "}
          <a
            target="_blank"
            className="is-active"
            href="https://twitter.com/jmfurlott"
          >
            @jmfurlott
          </a>
          .
        </p>
        <p>
          Also,{" "}
          <a
            href="https://s3.amazonaws.com/jmfurlott-blog/2022_Joseph_Furlott_Resume.pdf"
            target="_blank"
            className="is-active"
          >
            please see my resume
          </a>{" "}
          for more information about my work experience.
        </p>
      </Layout>
    )
  }
}

export default ContactIndex
